export const PAGE_SIZE = 10;
export const AFTER_API_TIME = 1000;
export const ViewModalBorderRadius = 2;
export const getApiUrl = (base) => {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'production') {
        return `https://api.constrogen.com/${base}`;
        // return `https://8363-2405-201-e005-31bf-e1a3-4867-8c96-2418.ngrok-free.app/${base}`;
    }
    return `https://dev.constrogen.com/${base}`;
};
