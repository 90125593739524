var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useEffect, useMemo, useState } from 'react';
import DataGrid from 'react-data-grid';
import Row from '../DGRow';
import { useAppSelector, selectFilterData, updateGridSetting, useAppDispatch } from '@igblsln/store';
import DGFilter from '../DGFilter';
import Dataview from '../../Dataview';
import './DGTable.scss';
import { EmptyRowsRenderer } from './EmptyRowsRenderer';
import { defaultValueGetter, getFormatter } from './Formatter';
import { classNames } from 'primereact/utils';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import { getEditor, onEditorNavigation } from './Editors';
import { Paginator } from 'primereact/paginator';
import PaginationTemplate from './PaginationTemplate';
const addRowItem = { addRowItem: true };
const loadingRowItem = { loading: true };
const loadingRows = [loadingRowItem];
const castColumn = (col, columnMap) => {
    const _a = col.props || {}, { field, header, editorType, type, defaultValue, selectOptions, displayValueGetter, editOnDoubleClick, editOnFocus, valueGetter, width, className } = _a, rest = __rest(_a, ["field", "header", "editorType", "type", "defaultValue", "selectOptions", "displayValueGetter", "editOnDoubleClick", "editOnFocus", "valueGetter", "width", "className"]);
    columnMap[field] = Object.assign({ key: field, name: header, width: width, cellClass: className, editable: !!editorType, editor: getEditor(editorType), selectOptions, editorOptions: {
            editOnClick: !editOnDoubleClick,
            editOnDoubleClick: editOnDoubleClick,
            editOnFocus: true,
            onNavigation: onEditorNavigation
        }, defaultValue,
        type,
        displayValueGetter, valueGetter: valueGetter || defaultValueGetter, formatter: getFormatter(type, field, valueGetter || defaultValueGetter, defaultValue) }, rest);
    return columnMap[field];
};
const getColumns = (children) => {
    const columns = React.Children.toArray(children);
    const columnMap = {};
    if (!columns) {
        return [[], columnMap];
    }
    if (Array.isArray(columns)) {
        return [columns
                .filter(c => {
                return !c.props.header.includes(" Code") ||
                    c.props.header.includes("Project Code");
            })
                .map(c => {
                return castColumn(c, columnMap);
            }), columnMap];
    }
    else {
        return [[castColumn(columns, columnMap)], columnMap];
    }
};
const getComparator = (sortColumn, column) => {
    const valueGetter = (column === null || column === void 0 ? void 0 : column.valueGetter) || defaultValueGetter;
    switch (column.type) {
        case "number":
            return (a, b) => {
                const aVal = valueGetter(a, sortColumn, column.defaultValue);
                const bVal = valueGetter(b, sortColumn, column.defaultValue);
                return aVal - bVal;
            };
        case 'bool':
            return (a, b) => {
                const aVal = valueGetter(a, sortColumn, column.defaultValue);
                const bVal = valueGetter(b, sortColumn, column.defaultValue);
                return aVal === bVal ? 0 : aVal ? 1 : -1;
            };
        default:
            return (a, b) => {
                const aVal = valueGetter(a, sortColumn, column.defaultValue);
                const bVal = valueGetter(b, sortColumn, column.defaultValue);
                return (aVal === null || aVal === void 0 ? void 0 : aVal.localeCompare) ? aVal === null || aVal === void 0 ? void 0 : aVal.localeCompare(bVal) : aVal > bVal;
            };
    }
};
const isAtBottom = ({ currentTarget }) => {
    return currentTarget.scrollTop + 10 >= currentTarget.scrollHeight - currentTarget.clientHeight;
};
function sortIcon({ sortDirection }) {
    return (React.createElement(React.Fragment, null, sortDirection !== undefined ? (sortDirection === 'ASC' ? React.createElement("i", { className: 'pi pi-fw pi-sort-amount-up-alt' }) : React.createElement("i", { className: 'pi pi-fw pi-sort-amount-down-alt' })) : null));
}
const DGTable = (_a) => {
    var { gridId, data, children, allowAdd, disableAdd, isDataLoading, newRowDefaults, OnRowsChanged, getBottomSummaryRows, emptyRowMessage, pagination, showExport, filterOptions, allowFilters, showGridView, gridTileRenderer, lazyLoad } = _a, rest = __rest(_a, ["gridId", "data", "children", "allowAdd", "disableAdd", "isDataLoading", "newRowDefaults", "OnRowsChanged", "getBottomSummaryRows", "emptyRowMessage", "pagination", "showExport", "filterOptions", "allowFilters", "showGridView", "gridTileRenderer", "lazyLoad"]);
    const [columns, columnMap] = useMemo(() => getColumns(children), []);
    const [sortColumns, setSortColumns] = useState([]);
    const [filters, setFilters] = useState({});
    const [savedFilters, setSavedFilters] = useState();
    const [rows, setRows] = useState([]);
    const [actualRows, setActualRows] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [first, setFirst] = useState(0);
    const dispatch = useAppDispatch();
    const filterSetting = useAppSelector(selectFilterData);
    const applyRowData = (d) => {
        setActualRows(d);
        if (allowAdd) {
            setRows([...d, addRowItem]);
        }
        else {
            setRows(d);
        }
    };
    const applyData = (data) => {
        if (!data) {
            applyRowData([]);
            return;
        }
        const filteredData = data === null || data === void 0 ? void 0 : data.filter((r) => {
            var _a, _b, _c;
            for (const key in filters) {
                if (!columnMap[key] || !filters[key])
                    continue;
                const valueGetter = (_a = columnMap[key]) === null || _a === void 0 ? void 0 : _a.valueGetter;
                const rVal = valueGetter(r, key, (_b = columnMap[key]) === null || _b === void 0 ? void 0 : _b.defaultValue);
                switch ((_c = columnMap[key]) === null || _c === void 0 ? void 0 : _c.filteringType) {
                    case 'number':
                        // if (Number(rVal) !== Number(filters[key])) {
                        if (!(rVal === null || rVal === void 0 ? void 0 : rVal.includes(filters[key]))) {
                            return false;
                        }
                        break;
                    case 'text':
                        if (!(rVal === null || rVal === void 0 ? void 0 : rVal.includes(filters[key]))) {
                            return false;
                        }
                        break;
                    case 'date':
                        if (!(rVal === null || rVal === void 0 ? void 0 : rVal.includes(filters[key]))) {
                            return false;
                        }
                        break;
                    default:
                        if (rVal !== filters[key]) {
                            return false;
                        }
                        break;
                }
            }
            return true;
        });
        if (sortColumns.length === 0) {
            applyRowData(filteredData);
        }
        else {
            applyRowData(filteredData.sort((a, b) => {
                for (const sort of sortColumns) {
                    const comparator = getComparator(sort.columnKey, columnMap[sort.columnKey]);
                    const compResult = comparator(a, b);
                    if (compResult !== 0) {
                        return sort.direction === 'ASC' ? compResult : -compResult;
                    }
                }
                return 0;
            }));
        }
    };
    const applyChangedRowData = (d) => {
        const r = d === null || d === void 0 ? void 0 : d.filter(x => x !== addRowItem);
        applyRowData(r);
        if (OnRowsChanged) {
            OnRowsChanged(r);
        }
    };
    const handleAddRow = () => {
        if (disableAdd) {
            return;
        }
        const newRow = Object.assign({}, (newRowDefaults || {}));
        const r = [...actualRows, newRow];
        setActualRows(r);
        setRows([r, addRowItem]);
        if (OnRowsChanged) {
            OnRowsChanged(r);
        }
    };
    const bottomSummaryRows = useMemo(() => {
        if (getBottomSummaryRows) {
            return getBottomSummaryRows(actualRows);
        }
        return [];
    }, [actualRows, getBottomSummaryRows]);
    useEffect(() => {
        // if (lazyLoad) {
        //     return;
        // }
        applyData(data);
    }, [data, lazyLoad, allowAdd, sortColumns, filters, disableAdd]);
    useEffect(() => {
        if (filterSetting && filterSetting[gridId]) {
            setSavedFilters(filterSetting[gridId]);
        }
    }, [gridId, filterSetting]);
    useEffect(() => {
        if (savedFilters) {
            dispatch(updateGridSetting({ gridId, value: savedFilters }));
            // localStorage.setItem(gridId, JSON.stringify(savedFilters));
        }
    }, [savedFilters]);
    useEffect(() => {
        setIsLoading(isDataLoading || false);
    }, [isDataLoading]);
    useEffect(() => {
        filterOptions && (filterOptions === null || filterOptions === void 0 ? void 0 : filterOptions.onChange(filters));
    }, [filters]);
    function handleScroll(event) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!lazyLoad || isLoading || !isAtBottom(event))
                return;
            setIsLoading(true);
            const newRows = yield lazyLoad.loadData((rows.length / lazyLoad.pageSize) + 1, sortColumns);
            applyData([...rows, ...newRows]);
            setIsLoading(false);
        });
    }
    const rowRenderer = (key, props) => {
        if (props.row === addRowItem) {
            const { viewportColumns } = props, rest = __rest(props, ["viewportColumns"]);
            const newViewportColumns = [Object.assign(Object.assign({}, viewportColumns[0]), { minWidth: 160, width: 160, cellClass: "action-cell-class", colSpan: () => viewportColumns.length, formatter: () => (React.createElement("div", null,
                        React.createElement(Button, { icon: "pi pi-plus", type: "button", disabled: disableAdd, label: "Add", tabIndex: 1, style: { width: 80 }, onClick: handleAddRow, className: "p-button-text" }))) })];
            return (React.createElement(Row, Object.assign({ key: key, viewportColumns: newViewportColumns }, rest)));
        }
        if (props.row === loadingRowItem) {
            const { viewportColumns } = props, rest = __rest(props, ["viewportColumns"]);
            const newViewportColumns = [Object.assign(Object.assign({}, viewportColumns[0]), { minWidth: 160, width: 160, cellClass: "action-cell-class", colSpan: () => viewportColumns.length, formatter: () => (React.createElement("div", { style: { paddingLeft: '45%' } },
                        React.createElement("span", null, "Loading..."))) })];
            return (React.createElement(Row, Object.assign({ key: key, viewportColumns: newViewportColumns }, rest)));
        }
        return (React.createElement(Row, Object.assign({ key: key }, props)));
    };
    return (React.createElement("div", { style: { minHeight: 'inherit' }, className: classNames('ig-grid', { 'ig-grid-no-filter': !allowFilters, 'ig-grid-filter': allowFilters }) },
        allowFilters &&
            React.createElement(DGFilter, { columns: columns, filters: filters, setFilters: setFilters, savedFilters: savedFilters || [], setSavedFilters: setSavedFilters, showExport: showExport }),
        !showGridView && React.createElement(React.Fragment, null,
            React.createElement(DataGrid, Object.assign({ className: 'rdg-light fill-grid', columns: columns, rows: isLoading ? loadingRows : rows, defaultColumnOptions: {
                    sortable: true,
                    resizable: true
                }, sortColumns: sortColumns, onSortColumnsChange: setSortColumns, onRowsChange: applyChangedRowData, onScroll: handleScroll, bottomSummaryRows: bottomSummaryRows, renderers: { sortIcon, rowRenderer, noRowsFallback: React.createElement(EmptyRowsRenderer, { msg: emptyRowMessage }) } }, rest)),
            (pagination && pagination.total) ?
                React.createElement("div", { className: "card" },
                    React.createElement(Paginator, { first: first, rows: pagination.pageSize, totalRecords: pagination.total, rowsPerPageOptions: [15, 20, 30], 
                        // @ts-ignore
                        template: PaginationTemplate, onPageChange: (e) => {
                            setFirst(e.first);
                            pagination.onChange(e.page + 1, e.rows);
                        } })) : null,
            isLoading && React.createElement(ProgressSpinner, { className: 'ig-grid-loader' })),
        showGridView && React.createElement(Dataview, { value: rows, onScroll: handleScroll, itemTemplate: gridTileRenderer, totalRecords: rows.length })));
};
export default DGTable;
