import React, { useEffect, useRef, useState } from 'react';
import { Button } from 'primereact/button';
import { ListBox } from 'primereact/listbox';
import { OverlayPanel } from 'primereact/overlaypanel';
import FilterChip from './FilterChip';
import './DGFilter.scss';
import SavedFilter from './SavedFilter';
import { useDownloadExportedDataQuery } from '@igblsln/store';
const DGFilter = ({ columns, filters, setFilters, savedFilters, setSavedFilters, showExport }) => {
    const [filterColumns, setFilterColumns] = useState();
    const [nonActiveColumns, setNonActiveColumns] = useState([]);
    const [selectedFilters, setSelectedFilters] = useState();
    const menu = useRef(null);
    const [startExport, setStartExport] = useState(false);
    const { data: downloadedData, isError, isSuccess } = useDownloadExportedDataQuery({ name: showExport, filter: JSON.stringify(filters) }, { skip: !showExport || !startExport });
    useEffect(() => {
        setStartExport(false);
    }, [isError, isSuccess]);
    const selectColumn = (val) => {
        var _a;
        if (val.data.active) {
            delete val.data['tempValue'];
            const mfilters = Object.assign({}, filters);
            delete mfilters[val.id];
            setFilters(mfilters);
        }
        val.data.active = !val.data.active;
        setSelectedFilters(undefined);
        // setFilterColumns(filterColumns?.filter(x => x.data?.active) || []);
        setNonActiveColumns((filterColumns === null || filterColumns === void 0 ? void 0 : filterColumns.filter(x => { var _a; return !((_a = x.data) === null || _a === void 0 ? void 0 : _a.active); })) || []);
        (_a = menu.current) === null || _a === void 0 ? void 0 : _a.hide();
    };
    useEffect(() => {
        const cols = columns === null || columns === void 0 ? void 0 : columns.filter(x => !!x.filteringType).map(x => ({
            id: x.key, label: x.name,
            data: {
                filterType: x.filteringType,
                value: filters[x.key],
                tempValue: filters[x.key],
                active: `${x.key}` in filters
            }
        }));
        setFilterColumns(cols);
        setNonActiveColumns((cols === null || cols === void 0 ? void 0 : cols.filter(x => { var _a; return !((_a = x.data) === null || _a === void 0 ? void 0 : _a.active); })) || []);
    }, [columns]);
    useEffect(() => {
        filterColumns === null || filterColumns === void 0 ? void 0 : filterColumns.map(x => {
            if (x.id) {
                x.data.tempValue = x.data.value = filters[x.id];
                x.data.active = `${x.id}` in filters;
            }
        });
        setNonActiveColumns((filterColumns === null || filterColumns === void 0 ? void 0 : filterColumns.filter(x => { var _a; return !((_a = x.data) === null || _a === void 0 ? void 0 : _a.active); })) || []);
    }, [filterColumns, filters]);
    return (React.createElement("div", { className: "flex align-items-center flex-wrap ", style: { padding: '.5rem' } },
        React.createElement(SavedFilter, { filters: filters, setFilters: setFilters, savedFilters: savedFilters, setSavedFilters: setSavedFilters, selectedFilters: selectedFilters, setSelectedFilters: setSelectedFilters }),
        filterColumns && filterColumns.map((col, idx) => (col.data.active && React.createElement(FilterChip, { key: idx, item: col, removeItem: selectColumn, onChange: (val) => col.id && setFilters(Object.assign(Object.assign({}, filters), { [col.id]: val })) }))),
        React.createElement(OverlayPanel, { ref: menu, dismissable: true, className: "overlaypanel-filter" },
            React.createElement(ListBox, { options: nonActiveColumns, onChange: (e) => selectColumn(e.value), style: { border: 'none' } })),
        React.createElement(Button, { className: 'p-button-text ml-3 mb-2', icon: "pi pi-plus", disabled: nonActiveColumns.length < 1, onClick: (event) => { var _a; return (_a = menu.current) === null || _a === void 0 ? void 0 : _a.toggle(event); }, "aria-controls": "popup_menu", "aria-haspopup": true },
            React.createElement("i", { className: "pi pi-filter add-filter" })),
        showExport &&
            React.createElement(Button, { style: { marginLeft: 'auto' }, label: 'Generate Report', onClick: () => {
                    if (confirm("Are you sure to export?"))
                        setStartExport(true);
                } })));
};
export default DGFilter;
