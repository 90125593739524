import React, { useEffect, forwardRef, useImperativeHandle, useState } from 'react';
import isEqual from 'lodash/isEqual';
import { useNavigate } from 'react-router-dom';
import { Toolbar } from 'primereact/toolbar';
import { Panel } from 'primereact/panel';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import { BlockUI } from 'primereact/blockui';
import { confirmDialog } from 'primereact/confirmdialog';
import { useForm } from "react-hook-form";
import ConfirmOnDirty from '../ConfirmOnDirty';
import './styles.scss';
import Loader from '../Loader';
export const getFormErrorMessage = (errMsg) => {
    return errMsg && React.createElement("small", { className: "p-error" }, errMsg);
};
const ManageLayout = ({ id, data, viewMode, dataFromLocation, isLoading, saveBtnLabel, discardBtnLabel, disableSaveBtn = false, isItemsTableChanged = false, bottomControl = false, isUpdating, baseRoute, description, additionalHeader, hideHeader = false, customDiscard = null, saveButton, moreSubmitItems, onSubmit, onError, renderForm }, selfRef) => {
    const navigate = useNavigate();
    const [promptNavigate, setPromptNavigate] = useState(false);
    const formData = dataFromLocation || data;
    const { control, formState: { errors, isDirty, dirtyFields }, register, reset, handleSubmit, getValues, setValue } = useForm({ defaultValues: formData });
    const exitPage = () => {
        if (promptNavigate) {
            if (customDiscard) {
                if (confirm("Are You Sure to Discard?")) {
                    customDiscard();
                }
            }
            else {
                confirmDialog({
                    message: 'Are you sure you want to discard?',
                    header: 'Confirmation',
                    icon: 'pi pi-exclamation-triangle',
                    accept: () => {
                        setPromptNavigate(false);
                        setTimeout(() => {
                            navigate(baseRoute);
                        }, 100);
                    },
                    reject: () => { }
                });
            }
        }
        else {
            customDiscard ? customDiscard() : navigate(baseRoute);
        }
    };
    useEffect(() => {
        setPromptNavigate((isDirty && Object.keys(dirtyFields).length > 0) || (dataFromLocation && !isEqual(data, dataFromLocation) || isItemsTableChanged));
    }, [isDirty, dirtyFields, dataFromLocation, data, isItemsTableChanged]);
    useImperativeHandle(selfRef, () => ({
        getIsDirty() {
            return isDirty;
        }
    }));
    useEffect(() => {
        if (dataFromLocation || data) {
            reset(dataFromLocation || data);
        }
    }, [dataFromLocation, data]);
    if (isLoading) {
        return React.createElement("div", { className: "custom-skeleton p-4" },
            React.createElement(Loader, null));
    }
    const handleKeydown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            event.stopPropagation();
        }
    };
    return (React.createElement("form", { className: 'h-full module-layout', onSubmit: handleSubmit(onSubmit, onError), onKeyDown: handleKeydown },
        React.createElement(ConfirmOnDirty, { isDirty: promptNavigate }),
        React.createElement(BlockUI, { blocked: isUpdating, template: React.createElement(ProgressSpinner, { style: { width: '50px', height: '50px' }, strokeWidth: "8", fill: "var(--surface-ground)", animationDuration: ".5s" }) },
            React.createElement(Panel, { className: 'h-full', headerTemplate: !hideHeader && React.createElement(Toolbar, { style: { backgroundColor: 'white', width: '100%', padding: bottomControl ? '0 1rem' : '1rem' }, 
                    // left={<h3>{description}/ {id ? 'Edit' : 'New'}</h3>}
                    left: React.createElement("div", { style: { display: 'flex' } },
                        React.createElement("h3", null,
                            id ? 'Update a ' : 'Create ',
                            description),
                        " ",
                        additionalHeader,
                        " "), right: !bottomControl && !viewMode &&
                        React.createElement(React.Fragment, null,
                            saveButton ? saveButton : React.createElement(Button, { id: 'submit', label: saveBtnLabel || 'Save', disabled: disableSaveBtn, type: "submit", style: { paddingRight: 20 }, className: "p-button-warning mr-3" }),
                            React.createElement(Button, { label: discardBtnLabel || 'Discard', className: 'p-button-plain', onClick: (e) => {
                                    e.preventDefault();
                                    customDiscard ? customDiscard() : exitPage();
                                } })) }) },
                React.createElement("div", { style: {
                        maxHeight: '99%',
                        maxWidth: '99%',
                        overflow: 'hidden auto',
                        pointerEvents: viewMode ? 'none' : 'all'
                    } }, renderForm(control, register, errors, getValues, setValue)),
                bottomControl && !viewMode &&
                    React.createElement("div", { style: { display: 'flex', justifyContent: 'center' } },
                        React.createElement(React.Fragment, null,
                            saveButton ? saveButton : React.createElement(Button, { id: 'submit', label: saveBtnLabel || 'Save', disabled: disableSaveBtn, type: "submit", style: { paddingRight: 20 }, className: "p-button-warning mr-3" }),
                            React.createElement(Button, { label: discardBtnLabel || 'Discard', className: 'p-button-plain', onClick: (e) => {
                                    e.preventDefault();
                                    exitPage();
                                } }),
                            moreSubmitItems))))));
};
export default forwardRef(ManageLayout);
