var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState } from 'react';
import { Menubar } from 'primereact/menubar';
import { SplitButton } from 'primereact/splitbutton';
import { getClientProps, logout, useAppDispatch, useGetAllCompaniesQuery, setGlobalCompany } from '@igblsln/store';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { MegaMenu } from 'primereact/megamenu';
import { templateFn } from '../NavLink';
import { useNavigate } from 'react-router-dom';
const Header = ({ routes, user }) => {
    const megamenu = true;
    const clientProps = getClientProps();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [displayModal, setDisplayModal] = useState(false);
    const { data: companies } = useGetAllCompaniesQuery();
    const toggleDarkMode = () => {
        document.body.classList.toggle('fluent-theme-dark');
    };
    const items = [
        {
            label: 'Profile',
            icon: 'pi pi-user-edit'
        },
        {
            label: 'Logout',
            icon: 'pi pi-sign-out',
            command: () => {
                dispatch(logout());
                navigate('/');
            }
        }
    ];
    const itemsLogin = [
        {
            label: 'Login',
            icon: 'pi pi-sign-out',
            command: () => {
                // window.location.hash = "/fileupload"
            }
        }
    ];
    // const leftContents = (
    //     <React.Fragment>
    //         {/* <i className="pi pi-bars p-toolbar-separator mr-2" /> */}
    //         <NavLinkButton to="/projects" label="Projects" className="mr-1" />
    //         <NavLinkButton to="/purchase" label="Purchases" className="mr-1" />
    //         <NavLinkButton to="/sales" label="Sales" className="mr-1" />
    //         <NavLinkButton to="/product" label="Products" className="mr-1" />
    //         <NavLinkButton to="/payment" label="Payments" className="mr-1" />
    //         <NavLinkButton to="/inventory" label="Inventory" className="mr-1" />
    //         <NavLinkButton to="/settings" label="Settings" className="mr-1" />
    //     </React.Fragment>
    // );
    const rightContents = (React.createElement(React.Fragment, null,
        React.createElement(SplitButton, { label: `${user === null || user === void 0 ? void 0 : user.first_name} ${user === null || user === void 0 ? void 0 : user.last_name}`, icon: "pi pi-user", model: !!user ? items : itemsLogin })));
    const toggle = (e) => {
        // if (menu.current.toggle) {
        //     menu.current.toggle(e);
        // }
    };
    const start = React.createElement("div", { style: {
            marginRight: '4rem',
            fontWeight: 'bold',
            fontSize: 24
        } },
        React.createElement(SplitButton, { label: `${clientProps.company_name || 'Loading...'}`, model: [
                {
                    label: 'Company Settings',
                    icon: 'pi pi-user-edit'
                },
                {
                    label: 'Change Company',
                    icon: 'pi pi-pencil',
                    command: () => {
                        setDisplayModal(true);
                    }
                }
            ] }));
    const megamenuItems = [
        {
            label: 'Project Management',
            items: [
                [
                    {
                        label: 'Projects',
                        items: [
                            {
                                label: 'Project Detail',
                                template: templateFn('/projects/project')
                            },
                            {
                                label: 'Unit Detail',
                                template: templateFn('/projects/unit')
                            },
                            {
                                label: 'Image Detail',
                                template: templateFn('/projects/images')
                            },
                            {
                                label: 'Project Status',
                                template: templateFn('/projects/projectstatus')
                            },
                            {
                                label: 'Project Type',
                                template: templateFn('/projects/projecttype')
                            },
                        ]
                    }
                ],
                [
                    {
                        label: 'Purchase',
                        items: [
                            {
                                label: 'Purchase Orders',
                                template: templateFn('/purchase/purchaseorder')
                            },
                            {
                                label: 'Customer Purchase',
                                template: templateFn("/purchase/customerpurchase")
                            },
                            {
                                label: 'Material Vendor Invoices',
                                template: templateFn('/purchase/vendorinvoice')
                            },
                            {
                                label: 'Goods Registers',
                                template: templateFn('/purchase/materialpurchase')
                            },
                        ]
                    }
                ],
                [
                    {
                        label: 'Sale',
                        items: [
                            {
                                label: 'Sale Agreement',
                                template: templateFn('/sales/saleagreement')
                            },
                            {
                                label: 'Sale Invoice',
                                template: templateFn('/sales/saleinvoice')
                            },
                            {
                                label: 'Construction Agreement',
                                template: templateFn('/sales/constructionagreement')
                            },
                        ]
                    },
                    {
                        label: 'Contracts',
                        items: [
                            {
                                label: 'Contractor Agreements',
                                template: templateFn('/contract/contractagreement')
                            },
                            {
                                label: 'Contractor Invoice',
                                template: templateFn('/contract/contractorinvoice')
                            },
                        ]
                    },
                ],
            ]
        },
        {
            label: 'Financial Operations',
            items: [
                [
                    {
                        label: 'Payments',
                        items: [
                            {
                                label: 'Make Payments',
                                template: templateFn("/payment/vendorpayment")
                            },
                            {
                                label: 'Receive Payments',
                                template: templateFn("/payment/salereceipt")
                            },
                            {
                                label: 'Extra Expenses',
                                template: templateFn("/payment/expenses")
                            },
                            {
                                label: 'Expense Type',
                                template: templateFn("/payment/expensetype")
                            },
                            {
                                label: 'Payment History',
                                template: templateFn("/payment/vendorpaymenthistory")
                            },
                            {
                                label: 'Account Transaction',
                                template: templateFn("/payment/debit")
                            },
                            {
                                label: 'Transaction History',
                                template: templateFn("/payment/transactionhistory")
                            },
                        ]
                    },
                ],
                [
                    {
                        label: 'Tax',
                        items: [
                            {
                                label: 'Purchase GST',
                                template: templateFn('/tax/purchasegst')
                            },
                            {
                                label: 'Sales GST',
                                template: templateFn('/tax/salesgst')
                            },
                            {
                                label: 'Purchase TDS',
                                template: templateFn('/tax/purchasetds')
                            },
                            {
                                label: 'Sale TDS',
                                template: templateFn('/tax/saletds')
                            },
                        ]
                    },
                ],
            ]
        },
        {
            label: 'Resource Management',
            items: [
                [
                    {
                        label: 'Items',
                        items: [
                            {
                                label: 'Material Items',
                                template: templateFn("/inventory/items")
                            },
                            {
                                label: 'Material Item Sub Type',
                                template: templateFn("/inventory/itemsubtypes")
                            },
                            {
                                label: 'Material Item Type',
                                template: templateFn("/inventory/itemtypes")
                            },
                            {
                                label: 'UOM',
                                template: templateFn("/inventory/uoms")
                            },
                            {
                                label: 'Purpose',
                                template: templateFn("/inventory/purposes")
                            },
                        ]
                    }
                ],
                [
                    {
                        label: 'Vendors',
                        items: [
                            {
                                label: 'Material Vendors',
                                template: templateFn("/vendor/vendor")
                            },
                            // {
                            //     label: 'Material Vendor Type',
                            //     template: templateFn("/vendor/vendortype")
                            // },
                        ]
                    },
                    {
                        label: 'Contractors',
                        items: [
                            {
                                label: 'Contractors',
                                template: templateFn('/contractor/contractor')
                            },
                            {
                                label: 'Contractor Type',
                                template: templateFn('/contractor/contractortype')
                            },
                        ]
                    },
                ],
                [
                    {
                        label: 'Empolyee',
                        items: [
                            {
                                label: 'Employees',
                                template: templateFn('/users/users'),
                            },
                            {
                                label: 'Roles',
                                template: templateFn("/users/roles"),
                            },
                            {
                                label: 'Payslip',
                                template: templateFn("/users/payslip"),
                            },
                        ]
                    },
                ],
                [
                    {
                        label: 'Bank Account',
                        items: [
                            {
                                label: 'Bank Accounts',
                                template: templateFn('/tax/bank/accounts')
                            },
                        ]
                    },
                    {
                        label: 'Customer',
                        items: [
                            {
                                label: 'Customers',
                                template: templateFn('/sales/customers')
                            },
                        ]
                    },
                ],
            ]
        },
        {
            label: 'Accessories',
            items: [
                [
                    {
                        label: 'Templates',
                        items: [
                            {
                                label: 'Contract Service',
                                template: templateFn('/template/contractservices'),
                            },
                            {
                                label: 'Payment Schedule',
                                template: templateFn('/template/paymentschedules'),
                            },
                        ]
                    },
                ],
            ]
        },
        // {
        //     label: 'CRM',
        //     items: [
        //         [
        //             {
        //                 label: 'Menu',
        //                 items: [
        //                     {
        //                         label: 'Dashboard',
        //                         template: templateFn('/crm/dashboard'),
        //                     },
        //                     {
        //                         label: 'Leads',
        //                         template: templateFn('/crm/leads'),
        //                     },
        //                     {
        //                         label: 'Site Visits',
        //                         template: templateFn('/crm/sitevisits'),
        //                     },
        //                     {
        //                         label: 'Property',
        //                         template: templateFn('/crm/properties'),
        //                     },
        //                 ]
        //             },
        //         ],
        //     ]
        // },
    ];
    return (React.createElement(React.Fragment, null,
        megamenu ?
            React.createElement(MegaMenu, { start: start, model: megamenuItems, end: rightContents })
            :
                React.createElement(Menubar, { model: routes.map(x => {
                        x.command = toggle;
                        return x;
                    }), end: rightContents }),
        React.createElement(Dialog, { header: `Change Company`, visible: displayModal, position: 'top', modal: true, style: { width: '40vw' }, onHide: () => setDisplayModal(false), draggable: false, resizable: false },
            React.createElement("div", { style: { padding: 15 } },
                React.createElement("div", { className: "field" },
                    React.createElement("label", { style: { fontSize: 18 }, className: 'col-4' }, " Companies List"),
                    React.createElement(Dropdown, { style: { width: '60%' }, 
                        // value={selectedStatus}
                        placeholder: 'Select a Company', onChange: (e) => __awaiter(void 0, void 0, void 0, function* () {
                            let company = companies === null || companies === void 0 ? void 0 : companies.find(x => x.id === e.value);
                            yield dispatch(setGlobalCompany(company));
                            yield setDisplayModal(false);
                            window.location.reload();
                        }), optionLabel: "name", optionValue: "id", options: (user === null || user === void 0 ? void 0 : user.company) || [] }))))));
};
export default Header;
